import React, { useState } from "react";
import imgC3 from "../../assets/image/akkadian/adhd_content3.png";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";


const PatientAction = ({ id, title, text, buttonText }) => {
    const { register, handleSubmit, errors } = useForm();
    const [isSubmitError, setIsSubmitError] = useState({ status: false, message: '' });
    const [isSuccessfulSubmit, setIsSuccessfulSubmit] = useState(false);

    const testRequest = (data) => {
        console.log('CRM', data);
        // Send to ACTIVECAMPAIGN
        const formData = new FormData();

        // Hidden field key/values.
        formData.append("u", "1");
        formData.append("f", "1");
        formData.append("s", "s");
        formData.append("c", "0");
        formData.append("m", "0");
        formData.append("act", "sub");
        formData.append("v", "2");
        formData.append("or", "188f0052-b7ea-4fb0-a5b7-b00defd0965b");

        // Form field key/values.
        formData.append("fullname", data.name);
        formData.append("email", data.email);
        formData.append("phone", data.phone);        
        formData.append("field[1]", data.state);
        formData.append("ca[1][v]", 'I would like to learn more about ADHD referrals. Please send me a referral guide.');
        console.log(formData);
        
        // Pass FormData values into a POST request to ActiveCampaign.
        // Mark form submission successful, otherwise return error state. 
        fetch('https://akkadianhealth.activehosted.com/proc.php', {
            method: 'POST',
            body: formData,
            mode: 'no-cors',
        })
        .then(response => {
            console.log(response);
/*             if (!response.ok) {
                throw new Error(response.status);
            } */
            setIsSubmitError({ status: false, message: '' });
            setIsSuccessfulSubmit(true);
        })
        .catch(err => {
            console.log(err);
            let errMessage = '';

            switch (err) {
                case 'Error: 402':
                    errMessage = "Incorrect format (CRM)";
                    break;
                default:
                    errMessage = 'Error. Please try again.'
            }

            setIsSubmitError({ status: true, message: errMessage });            
            setIsSuccessfulSubmit(false);
        });
        
    }



    const submitRequest = (data) => {

        data.message = 'I would like to learn more about ADHD referrals. Please send me a referral guide.';
        console.log('Send Email', data);


        // Send EMAIL
        sendEmail(data).then((response) => {
            console.log(response);
            if (!response.ok) {
                throw new Error(response.status);
            }
            setIsSubmitError({ status: false, message: '' });
            setIsSuccessfulSubmit(true);
        }).catch(
            err => {
                console.log(err);
                let errMessage = '';

                switch (err) {
                    case 'Error: 402':
                        errMessage = "Incorrect format (Email)";
                        break;
                    default:
                        errMessage = 'Email Error. Please try again.'
                }

                setIsSubmitError({ status: true, message: errMessage });
                setIsSuccessfulSubmit(false);
            }
        )
    }

    const sendEmail = async (data) => {
        const response = await fetch('/.netlify/functions/sendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })

        return response
    }

    return (
        <>
            {/* <!-- CTA Area --> */}
            <div id={id} className="cta-section overflow-hidden py-13 py-lg-15 bg-light-blue bg-pattern pattern-2 ">
                <Container>
                    <Row className="align-items-center">
                        <Col xl="5" md="6" data-aos="fade-right" data-aos-duration="800">
                            <div className="section-title pr-lg-7 mb-0 light-mode-texts">
                            <div className="d-flex justify-content-center align-items-center mb-4"
                                style={{ width: 'auto', height: 'auto', overflow: 'hidden'}}>
                                <img src={imgC3} alt="ADHD Assessment" className="img-fluid w-60 h-60" style={{ objectFit: 'cover' }} />
                            </div>
                                <h2 className="title gr-text-4 mb-6">
                                    {title}
                                </h2>
                                <p className="gr-text-8 pr-lg-7 mb-0">
                                    {text}
                                </p>
                            </div>
                        </Col>
                        <Col
                            xl="4"
                            lg="5"
                            md="6"
                            className="offset-xl-3 offset-lg-1 mt-9 mt-md-0"
                            data-aos="fade-left"
                            data-aos-duration="800"
                        >

                            <form onSubmit={handleSubmit(testRequest)} className="cta-form bg-white light-mode rounded-10 px-8 py-8">
                                {!isSuccessfulSubmit && <fieldset>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaName">Name </label>
                                        <input
                                            name="name"
                                            ref={register({
                                                required: true
                                            })}
                                            type="text"
                                            className="form-control gr-text-11"
                                            id="ctaName"
                                            placeholder="i.e. John Doe"
                                            required=""
                                        />
                                        {errors.name?.type === "required" && <p className="error text-red" role="alert">This field is required</p>}
                                    </div>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaEmail">Email </label>
                                        <input
                                            name="email"
                                            ref={register({
                                                required: true,
                                                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
                                            })}
                                            type="email"
                                            className="form-control gr-text-11"
                                            id="ctaEmail"
                                            placeholder="i.e. john@mail.com"
                                        />
                                        {errors.email?.type === "required" && <p className="error text-red" role="alert">This field is required</p>}
                                        {errors.email?.type === "pattern" && <p className="error text-red" role="alert">This field is required</p>}

                                    </div>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="ctaPhone">Phone </label>
                                        <input
                                            name="phone"
                                            ref={register({
                                                required: false
                                            })}
                                            type="text"
                                            className="form-control gr-text-11"
                                            id="ctaPhone"
                                            placeholder="i.e. 0412 000 000"
                                        />
                                    </div>
                                    <div className="form-group gr-text-11 font-weight-bold text-blackish-blue">
                                        <label htmlFor="state">State</label>
                                        <select
                                            name="state"
                                            ref={register({
                                                required: true
                                            })}
                                            id="state"
                                            className="form-control gr-text-11"
                                        >
                                            <option value="">Please select your state</option>
                                            <option value="SA">SA</option>
                                            <option value="QLD">QLD</option>
                                            <option value="VIC">VIC</option>
                                            <option value="NSW">NSW</option>
                                            <option value="WA">WA</option>
                                            <option value="Tas">Tas</option>
                                            <option value="ACT">ACT</option>
                                            <option value="NT">NT</option>
                                        </select>
                                        {errors.state?.type === "required" && <p className="error text-red" role="alert">This field is required</p>}
                                    </div>
                                    <Button type="submit" className="gr-hover-y rounded-8 w-100">
                                        {buttonText}
                                    </Button>
                                </fieldset>}
                                {isSuccessfulSubmit && <p className="success text-green mb-0" role="alert">Submitted Successfully</p>}
                                {isSubmitError.status && <p className="error text-red mb-0" role="alert">{isSubmitError.message}</p>}
                            </form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default PatientAction;
