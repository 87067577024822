import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import Divider from '../../sections/akkadian/Divider';
import imgBanner from "../../assets/image/akkadian/selfhelp.jpg";
import docInfo from "../../assets/docs/Patient_Info.pdf";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import PatientAction from "../../sections/akkadian/PatientBlock";


const GenPsyhiatry = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >

	
        <Helmet>
          <title>Akkadian Health - General Psychiatry Services | Telehealth Australia</title>
          <meta
            name="description"
            content="Comprehensive mental health care. Affordable General Psychiatry Assessments and Services in Australia via telehealth."
          />
        </Helmet>

        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="section-title text-center px-md-12 mb-lg-6 mb-5">
                  <h1 className="title gr-text-4 mb-3 mb-lg-4">General Psychiatry Services</h1>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-md-3 pt-12 pt-lg-14">
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6"></div>
              </Col>
              <Col xs="8">
                <div className="banner-fluid-image pt-lg-6">
                  <img src={imgBanner} alt="" className="d-flex text-center w-100 rounded-12" />
                </div>
              </Col>
              <Col xs="2">
                <div className="banner-fluid-image pt-lg-6"></div>
              </Col>
            </Row>

            <Row className="my-10 mb-lg-12 pr-xl-10">
              <Col lg="4">
                <h2 className="title gr-text-5 mb-3">
                  Why choose Akkadian Health for General Psychiatry?
                </h2>
              </Col>
              <Col lg="8">
                <h3 className="gr-text-8">Cost Competitive</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  As a private clinic, we try to keep our fees (and out-of-pocket expenses) as low as possible.
                  They are currently amongst the lowest fees in Australia for this type of service.
                </p>
                <p className="gr-text-10 mb-7 mb-lg-10">
                  Please refer to our <Link to={`/faq#fees`} className={`gr-text-12`}>
                    FAQ
                  </Link>{' '}
                  for the latest FEE SCHEDULE under "<i>What are your fees?</i>"
                </p>

                <h3 className="gr-text-8">Comprehensive</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  Our Assessments are conducted by highly experienced, RANZCP-registered psychiatrists in
                  Australia.
                </p>
                <p className="gr-text-10 mb-7 mb-lg-10">
                  Your referring GP (and you) will receive a comprehensive assessment report and treatment plan.
                </p>

                <h3 className="gr-text-8">Convenient, fast & efficient</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  Our Assessments are delivered through accessible telehealth (video conference) services by
                  our dedicated team of over 20 clinicians with minimal wait times.
                </p>

                <h3 className="gr-text-8">End-to-end support</h3>
                <p className="gr-text-10 mb-7 mb-lg-10">
                  Akkadian Health also offers treatment via registered Psychologists for your ongoing support needs.
                </p>
              </Col>
            </Row>

            </Container>
          <div className="px-2 text-center py-7 py-lg-10">
                <PatientAction id={"contact"} buttonText={"Request Guide"} title={"Need a Referral for an ADHD Assessment?"} text={"Please submit this form to receive a personalised ADHD referral guide to take to your GP."} />
			 		</div>			

       <Divider />
      <Container>
			<Row className="justify-content-center py-lg-10 py-8">
			  <Col lg="12">
				<h2 className="title gr-text-5 mb-7">
					Things You Need to Know Before Proceeding
				</h2>
				
				
				<div>
				<h3 className="gr-text-8">Valid Referrals</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>Referrals must be addressed to 'Akkadian Health'.</strong> They must contain sufficient information about your medical history and presenting complaint (including a request for a psychiatric assessment).
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
				Please note: We will need your Medicare Number. If you are not registered for Medicare in Australia, you will be required to pay the full private fees as Medicare rebates will not apply. 
				</p>
				
				<h3 className="gr-text-8">Medication</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>Our Psychiatrists do not prescribe medication.</strong> Your comprehensive treatment plan guides your referring GP to prescribe and manage any recommended medication. It is your responsibility to ensure that your GP will enact your treatment plan (which includes initiating the recommended medication) and provide the necessary follow-up care. Our clinicians can work closely with your GP to ensure this happens. Refer to our <Link to={`/faq#meds`} className={`gr-text-12`}> FAQ </Link>: "<i>What if I need medication?</i>" for more information before proceeding.
				</p>			
					
				</div>
			
			  </Col>
			</Row>				
			
          </Container>
        </div>
        <Divider />
                <div className="px-12 text-center py-7 py-lg-10">
                  <p className="gr-text-8 mb-1">
						Please review our new <Link to={`/faq`} className={`gr-text-9`}> FAQ </Link> for more detailed information.
				  </p>
				 </div>
				 <div className="px-12 text-center mb-8 mb-lg-10">
				 <Link to={`/faq`}>
					<Button variant="warning" className="gr-hover-y w-80 w-sm-auto">FAQ</Button>
				 </Link>
				</div>
 
      </PageWrapper>
    </>
  );
};
export default GenPsyhiatry;

